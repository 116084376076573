// extracted by mini-css-extract-plugin
export var backgroundImage = "TextHero-module--backgroundImage--a2b85";
export var blackTheme = "TextHero-module--blackTheme--02b69";
export var boldSubtitle = "TextHero-module--boldSubtitle--94fee";
export var children = "TextHero-module--children--09e95";
export var darkTheme = "TextHero-module--darkTheme--bdfb2";
export var largeSubtitle = "TextHero-module--largeSubtitle--c549c";
export var leftAligned = "TextHero-module--leftAligned--3862b";
export var leftIcon = "TextHero-module--leftIcon--9ff81";
export var mediumSize = "TextHero-module--mediumSize--971d9";
export var rightIcon = "TextHero-module--rightIcon--cc27f";
export var softTheme = "TextHero-module--softTheme--ce6b5";
export var sticky = "TextHero-module--sticky--814f8";
export var subtitle = "TextHero-module--subtitle--93175";
export var textHero = "TextHero-module--textHero--9ac8d";