import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import TextHero from '@src/components/TextHero';
import TextArticle from '@src/components/TextArticle/TextArticle';
import Container from '@src/components/Container';

const Kopytrymme = ({ data }) => {
  const {
    headLine,
    pageHeader,
    pageHeaderDescription: { pageHeaderDescription },
  } = data.contentfulPage;

  return (
    <Layout>
      <SEO title={headLine} />
      <Container>
        <TextHero
          className="textHero"
          leftAligned
          title={pageHeader}
        ></TextHero>
        <div style={{ marginTop: 100, marginBottom: 100 }}>
          <TextArticle bigLinks alignment="center">
            {pageHeaderDescription}
          </TextArticle>
        </div>
      </Container>
    </Layout>
  );
};

export default Kopytrymme;

export const query = graphql`
  query AllmannaVillkorKoputrymme {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor/koputrymme" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
    }
  }
`;
