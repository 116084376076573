import React, { CSSProperties } from 'react';

import * as styles from './TextHero.module.scss';
import { Theme } from '../../styles/theme';
import classNames from 'classnames';
import Markdown from '../markdown';
import { ContentfulImage } from '@src/hooks/useContentfulImage.hook';
import { GatsbyImage } from 'gatsby-plugin-image';

type TextHeroProps = Omit<React.HTMLProps<HTMLElement>, 'size'> & {
  title: string;
  subtitle?: string;
  boldSubtitle?: boolean;
  largeSubtitle?: boolean;
  size?: 'large' | 'medium';
  leftAligned?: boolean;
  sticky?: boolean;
  theme?: Theme;
  titleStyle?: CSSProperties;
  backgroundImage?: ContentfulImage;
  className?: string;
  LeftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  RightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

function TextHero({
  title,
  subtitle,
  boldSubtitle,
  largeSubtitle,
  theme,
  children,
  size = 'large',
  leftAligned,
  sticky,
  titleStyle,
  backgroundImage,
  className,
  LeftIcon,
  RightIcon,
  ...rest
}: TextHeroProps) {
  const classes = classNames({
    [styles.textHero]: true,
    [styles.blackTheme]: theme === 'black',
    [styles.darkTheme]: theme === 'dark',
    [styles.softTheme]: theme === 'soft',
    [styles.leftAligned]: leftAligned,
    [styles.sticky]: sticky,
    [styles.mediumSize]: size === 'medium',
    [styles.largeSubtitle]: largeSubtitle,
    [styles.boldSubtitle]: boldSubtitle,
    [className]: className,
  });

  const splits = title.split('&nbsp;');

  return (
    <header className={classes} {...rest}>
      {backgroundImage && (
        <GatsbyImage
          className={styles.backgroundImage}
          image={backgroundImage.imageData}
          alt={backgroundImage.title}
        />
      )}
      <div id="TextHero" className={styles.heroContainer}>
        {LeftIcon && <LeftIcon className={styles.leftIcon} />}
        {RightIcon && <RightIcon className={styles.rightIcon} />}
        <h1 style={titleStyle}>
          {splits.map((s, i) => (
            <span key={i}>
              {s}
              {i < splits.length - 1 ? <>&nbsp;</> : ''}
            </span>
          ))}
        </h1>
      </div>
      {subtitle && <Markdown className={styles.subtitle}>{subtitle}</Markdown>}
      {children && <div className={styles.children}>{children}</div>}
    </header>
  );
}

export default TextHero;
